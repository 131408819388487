.cmp-form-text {
  display: flex;
  flex-direction: column;
  margin: $primitives-size-050 0;

  label {
    margin-bottom: $primitives-size-050;
  }
}

.cmp-form-text__text,
.cmp-form-text__textarea {
  border: 1px solid $borders-border-grey;
  border-radius: 0.125rem;
  padding: $primitives-size-150 $primitives-size-200;

  &:focus {
    border-color: $borders-border-darkgreen;
    outline: none;
  }
  &:required:focus:invalid {
    border-color: $functional-error;
  }
}

.cmp-form-text__help-block {
  color: $text-text-grey;
  font-size: 0.8rem;
  margin: 0 1.1rem;
  order: 3;
}
