.cmp-tabs {
  background-color: $container-container-black;
  margin: 0;

  .cmp-title {
    padding-left: 0;
  }
}

.cmp-tabs__tablist {
  margin: 0 auto;
  max-width: $default-container-width;
  padding: 0 $primitives-size-200;
}

.cmp-tabs__tab {
  border: none;
  border-left: 4px solid transparent;
  color: $text-text-light;
  font-family: $font-family-title;
  font-size: 1em;
  font-weight: 700;
  margin: 0;
  outline: none;
  padding: 1rem 1.5rem;

  @media screen and (min-width: $breakpoint--desktop) {
    border-left: none;
    border-top: 4px solid transparent;
  }
}
.cmp-tabs__tab--active {
  border-left: 4px solid $borders-border-green;
  color: $text-text-green;
  @media screen and (min-width: $breakpoint--desktop) {
    border-left: none;
    border-top: 4px solid $borders-border-green;
  }
}

.cmp-tabs__tabpanel {
  background-color: $container-container-white;
  padding-bottom: $primitives-size-600;
  padding-top: 1px;

  > .container {
    margin: 0 auto;
    max-width: $default-container-width;
    padding: 0 $primitives-size-200;
  }
}
.cmp-tabs__tabpanel--active {}
