.cmp-title {
  color: $text-text-dark;
  padding: $primitives-size-800 $primitives-size-200 $primitives-size-400;
  margin: 0 auto;
  max-width: $default-container-width;

  // h2.cmp-title__text,
  // h3.cmp-title__text,
  // h4.cmp-title__text,
  // h5.cmp-title__text,
  // h6.cmp-title__text {
  //   max-width: $max-text-width;
  // }

  .container--space-cage & {
    padding-left: 0;
    padding-right: 0;
  }

  .container--bg-black & {
    color: $text-text-light;
  }

  .title--left & {
    text-align: left;
  }

  .title--right & {
    text-align: right;
  }

  .title--center & {
    text-align: center;
  }

  .title--color-dark & {
    color:$text-text-dark;
  }

  .title--color-light & {
    color: $text-text-light;
  }

  .title--color-green & {
    color: $text-text-green;
  }

  .title--color-darkgreen & {
    color: $text-text-darkgreen;
  }

  .title--color-grey & {
    color: $text-text-grey;
  }

  .title--highlight-neon & {
    h1, h2, h3, h4, h5, h6 {
      margin: 0;
      padding: 0.2rem;
    }

    background-color: $container-container-green;
    color: $text-text-dark;
    width: fit-content;
    margin: 0;
    padding: 0;
  }

  .title--strip-left &,
  .title--strip-right & {
    .cmp-title {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  @media screen and (min-width: $breakpoint--desktop) {
    .title--strip-left &,
    .title--strip-right & {
      .cmp-title__text {
        background-color: $container-container-green;
        color: $text-text-dark;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        font-size: 3rem;
        font-weight: 500;
        line-height: 4.5rem;
        font-family: $font-family-copy;
        padding: 0.5rem 1.5rem;
        position: relative;
        &:after {
          content:'';
          position: absolute;
          top: 0;
          height: 100%;
          background-color: $container-container-green;
          width: 50vw;
        }
      }
    }

    .title--strip-left & {
      .cmp-title__text {
        &:after {
          left: -50vw;
        }
      }
    }

    .title--strip-right & {
      .cmp-title__text {
        &:after {
          left: 100%;
        }
      }
    }
  }

  .title--space-top-800 & {
    padding-top: $primitives-size-800;
    .cmp-title__text {
      margin-top: 0;
    }
  }

  .title--space-top-400 & {
    padding-top: $primitives-size-400;
    .cmp-title__text {
      margin-top: 0;
    }
  }

  .title--space-top-0 & {
    padding-top: 0;
    .cmp-title__text {
      margin-top: 0;
    }
  }

  .title--space-bottom-800 & {
    padding-bottom: $primitives-size-800;
    .cmp-title__text {
      margin-bottom: 0;
    }
  }

  .title--space-bottom-400 & {
    padding-bottom: $primitives-size-400;
    .cmp-title__text {
      margin-bottom: 0;
    }
  }

  .title--space-bottom-0 & {
    padding-bottom: 0;
    .cmp-title__text {
      margin-bottom: 0;
    }
  }

  .title--subtitle & {
    .cmp-title__text {
      font-size: $font-size-subtitle-mobile;
      font-weight: 600;
      line-height: $line-height-subtitle;
      font-family: $font-family-copy;
    }

    @media screen and (min-width: $breakpoint--desktop) {
      .cmp-title__text {
        font-size: $font-size-subtitle-desktop;
      }
    }
  }

  .title--subtitle-lowercase & {
    .cmp-title__text {
      text-transform: none;
    }
  }

  .title--wrap-balance {
    text-wrap: balance;
  }

}

.title + .title {
  .cmp-title {
    padding-top: 0;
    margin-top: 0;
  }
}

// CET newish title classes with namespace prefix "cet"
.cet-cmp-title {
  margin: 0;
  margin-block: 0;
  font-weight: normal;
  line-height: var(--cet-line-height);
  text-transform: initial;
}

.cet-cmp-title--uppercase {
  text-transform: uppercase;
}

.cet-cmp-title--bold {
  font-weight: bold;
}

/* Heading Layouts */
.cet-cmp-title--title-1 {
  font-family: var(--cet-font-akzidenz), sans-serif;
  font-size: var(--cet-font-size-title-1);
  line-height: var(--cet-line-height-title-1);
  font-weight: 700;

  @media screen and (min-width: 768px) {
    font-size: var(--cet-font-size-title-1-lg);
  }
}

.cet-cmp-title--title-2 {
  font-family: var(--cet-font-akzidenz), sans-serif;
  font-size: var(--cet-font-size-title-2);
  font-weight: 700;

  @media screen and (min-width: 768px) {
    font-size: var(--cet-font-size-title-2-lg);
  }
}

.cet-cmp-title--title-3 {
  font-family: var(--cet-font-akzidenz), sans-serif;
  font-size: var(--cet-font-size-title-3);
  font-weight: 700;

  @media screen and (min-width: 768px) {
    font-size: var(--cet-font-size-title-3-lg);
  }
}

.cet-cmp-title--highlight {
  color: var(--cet-color-neutral-black);
  background: var(--cet-color-accent-100);
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  /* Adding fixed vertical padding of "3px", so that the background has the same height as the line-height */
  padding: 2px;
  @media screen and (min-width: 768px) {
    padding: 3px 2px;
  }
}

.cet-cmp-title--highlight.cet-cmp-title--inverted {
  background: var(--cet-color-accent-40);
}

.cet-cmp-title--st-1 {
  font-family: var(--cet-font-montreal), sans-serif;
  font-size: var(--cet-font-size-st-1);

  @media screen and (min-width: 768px) {
    font-size: var(--cet-font-size-st-1-lg);
  }
}

.cet-cmp-title--st-2 {
  font-family: var(--cet-font-montreal), sans-serif;
  font-size: var(--cet-font-size-st-2);

  @media screen and (min-width: 768px) {
    font-size: var(--cet-font-size-st-2-lg);
  }
}

.cet-cmp-title--st-3 {
  font-family: var(--cet-font-montreal), sans-serif;
  font-size: var(--cet-font-size-st-3);

  @media screen and (min-width: 768px) {
    font-size: var(--cet-font-size-st-3-lg);
  }
}

.cet-cmp-title--st-4 {
  font-family: var(--cet-font-montreal), sans-serif;
  font-size: var(--cet-font-size-st-4);

  @media screen and (min-width: 768px) {
    font-size: var(--cet-font-size-st-4-lg);
  }
}

.cet-cmp-title--label-3 {
  font-family: var(--cet-font-akzidenz), sans-serif;
  font-size: var(--cet-font-size-label-3);
  line-height: var(--cet-line-height-label-3);
}

.cet-cmp-title-space-m--static {
  margin-block-start: var(--cet-space-m);
}

.cet-cmp-title-space-2xl--dyn {
  margin-block-start: var(--cet-space-m);
  @media screen and (min-width: 768px) {
    margin-block-start: var(--cet-space-2xl);
  }
}

.cet-cmp-title-space-3xl--static {
  margin-block-start: var(--cet-space-3xl);
}

.cet-cmp-title-space-3xl--dyn {
  margin-block-start: var(--cet-space-2xl);
  @media screen and (min-width: 768px) {
    margin-block-start: var(--cet-space-3xl);
  }
}
