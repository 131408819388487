.teaser {

  .cmp-teaser__action-link {
    margin-right: $primitives-size-100;
  }

  &.teaser--left {
    .cmp-teaser__content {
      text-align: left;
    }
    .cmp-teaser__action-container a {
      margin-right: $primitives-size-100;
    }
  }

  &.teaser--right {
    .cmp-teaser__content {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      text-align: right;
    }
    .cmp-teaser__action-container a {
      margin-left: $primitives-size-100;
      margin-right: 0;
    }
  }

  &.teaser--center {
    .cmp-teaser__content {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    .cmp-image {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .cmp-teaser__action-container { 
      align-items: center;
      a {
        margin-right: $primitives-size-100;
      }
    }

  }

  &.teaser--image-border-bottom-highlight {
    .cmp-image__image {
      border-bottom: $primitives-size-150 solid $borders-border-green;
    }
  }

  &.teaser--overlay-dark {
    .cmp-teaser__image{
      position: relative;
    }

    .cmp-teaser__image:before {
      content: '';
      height: 100%;
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.35);
      position: absolute;
    }

    &.teaser--image-border-bottom-highlight {
      .cmp-teaser__image:before {
        height: calc(100% - $primitives-size-150);
      }
    }
  }

}

.teaser--default,
.teaser--full-image {
  .cmp-image {
    margin-top: 0;
  }

  .cmp-image__image{
    width: fit-content;
  }

  .cmp-teaser {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .cmp-teaser__content {
    grid-area: 2 / 1 / 3 / 2;
    padding-bottom: 1rem;
  }

  .cmp-teaser__description {
    margin-bottom: 1rem;
  }

  .cmp-teaser__image {
    grid-area: 1 / 1 / 2 / 2;
    margin-bottom: 1.25rem;
  }

  .cmp-teaser__title-link {
    text-decoration: none;
  }

  .cmp-teaser__action-container {
    display: flex;
    flex-direction: column;


   .cmp-teaser__action-link:first-child {
      @extend %cta;
      @extend %cta--primary-inverted;
    }
  }

  .cmp-teaser__action-link + .cmp-teaser__action-link {
    color: $text-text-darkgreen;
  }

  .container--bg-black & {
    color: $text-text-light;

    .cmp-teaser__action-container {

     .cmp-teaser__action-link:first-child {
        @extend %cta--primary;
      }
    }
  
    .cmp-teaser__action-link + .cmp-teaser__action-link {
      color: $text-text-green;
    }
    
  }
}

.teaser--full-image {
  .cmp-image__image {
    width: 100%;
  }
}

.teaser__tags {
  padding-bottom: 1rem;
  margin: $primitives-size-400 0;
  span {
    border-radius: 22px;
    border: 2px solid $container-container-white;
    display: inline-block;
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    font-family: 'plexmono';
  }
  
}