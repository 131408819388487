.header__container {
  display: flex;
  align-items: center;
  gap: var(--cet-space-m);
  padding: 0.625rem 1rem;
  margin: 0 auto;
  max-width: var(--cet-max-width);

  @media screen and (min-width: 1024px) {
    padding: var(--cet-space-m) 2rem;
  }
}

.header__home-link {
  margin-right: auto;
  color: var(--cet-color-neutral-white);
  cursor: pointer;
}

/* Override default tertiary color with a higher specificity */
.header__menu-toggle.header__menu-toggle {
  background-color: var(--cet-color-neutral-black);
  color: var(--cet-color-neutral-white);
  padding: 0;
}

.header__menu-toggle-icon-menu,
.header__menu-toggle-icon-close {
  display: inline-flex;
}

.header__menu-toggle[aria-expanded="true"] .header__menu-toggle-icon-menu {
  display: none
}

.header__menu-toggle[aria-expanded="false"] .header__menu-toggle-icon-close {
  display: none
}

.header__main-nav {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  padding: 0;
  margin: 0 auto;
  background: var(--cet-color-neutral-black);
  border-top: 2px solid var(--cet-color-accent-100);
  @media screen and (min-width: 1024px) {
    position: static;
    display: block;
  }
  &:before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: var(--cet-color-backdrop-medium);
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  &-controls {
    display: none;
    justify-content: space-between;
    align-items: center;
    background-color: var(--cet-color-neutral-black);
    padding: 0 1rem 1rem;

    &--title {
      font-family: var(--cet-font-montreal), sans-serif;
      font-size: var(--cet-font-size-body-2);
      font-weight: 700;
      line-height: var(--cet-line-height);
      color: var(--cet-color-neutral-white);
    }

    &-show {
      display: flex;
    }
  }
}

.header__main-nav--expanded {
  display: block;
}

.header__main-nav-container {
  padding: var(--cet-space-xs) 0 0 0;
  margin: 0 auto;
  max-width: var(--cet-max-width);

  @media screen and (min-width: 1024px) {
    /* Instead of adjusting navigation items +8px */
    padding: var(--cet-space-3xs) var(--cet-space-l) 0 var(--cet-space-l);
  }
}

.header-nav__auth {
  display: inline-flex;
  gap: var(--cet-space-xs);
  margin: var(--cet-space-l) var(--cet-space-xs);

  &-hide {
    display: none;
  }
}

.cmp-navigation__item {
  &:hover {
    cursor: pointer;

    & > .cmp-navigation__item-link {
      color: var(--cet-color-accent-100);

      & .cmp-navigation__item-title {
        text-decoration: underline .1rem var(--cet-color-accent-100);
      }

      .cmp-navigation__item-description {
        color: var(--cet-color-accent-10);
      }

      & > .cmp-navigation__item-icon {
        filter: brightness(0) saturate(100%) invert(84%) sepia(59%) saturate(588%) hue-rotate(16deg) brightness(103%) contrast(104%);
      }
    }
  }

  & > .cmp-navigation__item-link {
    &:hover {
      cursor: pointer;
    }
  }

  &--active {
    & > .cmp-navigation__item-link {
      color: var(--cet-color-accent-100);

      .cmp-navigation__item-description {
        color: var(--cet-color-accent-10);
      }

      & > .cmp-navigation__item-icon {
        filter: brightness(0) saturate(100%) invert(84%) sepia(59%) saturate(588%) hue-rotate(16deg) brightness(103%) contrast(104%);
      }
    }
  }

  &-expandable-level0 {
    &--open {
      & .cmp-navigation__item--level-1 {
        pointer-events: auto;
      }

      & > .cmp-navigation__group {
        padding: 0;
        border-top: none;

        & > .cmp-navigation__item-link {
          display: none;
        }
      }
    }
  }

  //items levels
  &--level-0 {
    padding: var(--cet-space-s) var(--cet-space-xs);

    & > .cmp-navigation__group {
      display: none;
      position: absolute;
      background: #373737;
      width: 100%;
      height: auto;
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0;
      left: 0;
      cursor: auto;
      box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
      scrollbar-width: none;

      &--level-1-visible {
        display: grid;
      }

      .cmp-navigation__item-highlight {
        & .cmp-navigation__item-description {
          margin-bottom: var(--cet-space-s);
        }
      }

      @media screen and (min-width: 600px) {
        &:has(.cmp-navigation__item-highlight) {
          grid-template-columns: 1fr 1fr;

          .cmp-navigation__item--level-1 {
            padding: var(--cet-space-2xs);

            &.cmp-navigation__item-highlight {
              width: fit-content;
              height: fit-content;
              grid-area: 1 / 1;
            }
          }
        }
      }

      @media screen and (min-width: 1024px) {
        background-color: var(--cet-color-neutral-black);;
        padding: var(--cet-space-s);
        width: auto;
        top: 100%;

        &:has(.cmp-navigation__item--level-1-expanded) {
          border-radius: 0 0 0 var(--cet-space-4xs);
        }

        &:has(.cmp-navigation__item-highlight) {
          grid-template-columns: minmax(0, 370px) 1fr;

          .cmp-navigation__item--level-1 {
            align-items: flex-start;

            &.cmp-navigation__item-highlight {
              padding-left: 0;
            }
          }
        }
      }
    }

    & > .cmp-navigation__item-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background: transparent;
      padding: 0;
      border: none;

      & svg {
        width: 1.25rem;
        height: 1.25rem;
        display: inline-block;
        margin-left: 0.5rem;
        transition: transform 0.2s;
      }
    }

    &:not(:first-of-type) {
      border-top: 1px solid #666666;
    }

    &-hide {
      padding: 0;

      & .cmp-navigation__item--level-1 {
        pointer-events: auto;
      }

      & > .cmp-navigation__item-link {
        display: none;
      }
      &:not(:first-of-type) {
        border-top: none;
      }
    }

    @media screen and (min-width: 1024px) {
      top: 0;

      &:first-of-type {
        padding-left: 0;
      }
      &:not(:first-of-type) {
        border-top: none;
      }

      &.cmp-navigation__item--level-0-expanded {
        & > .cmp-navigation__item-link {
          & > svg {
            transform: rotate(180deg);
          }
        }

        & > .cmp-navigation__group {
          animation: fadeIn 0.2s ease-in-out;
        }

        & > .cmp-navigation__item-link {
          color: var(--cet-color-accent-100);

          & .cmp-navigation__item-title {
            text-decoration: underline .1rem var(--cet-color-accent-100);
          }
        }
      }
    }
  }

  &--level-1 {
    display: flex;
    align-items: flex-start;
    width: auto;
    height: auto;
    padding: var(--cet-space-2xs);
    transition: background-color 0.2s;
    background-color: transparent;
    cursor: pointer;
    pointer-events: none;

    & .cmp-navigation__item-link {
      display: flex;
      align-items: flex-start;
      width: 100%;
      font-family: var(--cet-font-montreal), sans-serif;
      font-size: var(--cet-font-size-body-2);
      font-weight: 700;
      line-height: var(--cet-line-height);
      background: transparent;
      padding: 0;
      border: none;

      & .cmp-navigation__item-icon {
        width: 2rem;
        height: 2rem;
        margin-right: 1.25rem;
        align-self: flex-start;
      }

      & .cmp-navigation__item-thumb {
        width: 100%;
        height: auto;
        border-radius: var(--cet-space-4xs);

        @media screen and (min-width: 600px) {
          max-width: none;
        }

        @media screen and (min-width: 1024px) {
          max-width: 310px;
        }
      }

      &:has(.cmp-navigation__item-thumb) {
        flex-direction: column-reverse;
        align-items: flex-start;
      }

      & .cmp-navigation__link-text {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        min-width: 200px;

        &:has(.cmp-navigation__item-description) {
          .cmp-navigation__item-title {
            margin-bottom: 0.375rem;
          }
        }
      }

      & .cmp-navigation__item-description {
        font-size: var(--cet-font-size-caption-2);
        font-weight: 500;
        line-height: 1.125rem;
        margin-bottom: 0;
        text-align: start;

        @media screen and (min-width: 600px) {
          max-width: 300px;
        }
      }

      & svg {
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;

        @media screen and (min-width: 1024px) {
          margin-left: 1.375rem;
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      &:before {
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        align-self: center;
      }
    }

    &:has(.cmp-navigation__item-highlight) {
      padding: var(--cet-space-xs);
    }

    &-expanded {
      background-color: var(--cet-color-neutral-80);
    }

    @media screen and (min-width: 1024px) {
      width: 100%;
      align-items: center;
      pointer-events: auto;
      border-radius: 0.25rem;

      &:hover {
        background-color: var(--cet-color-neutral-80);

        &.cmp-navigation__item-highlight {
          background-color: var(--cet-color-neutral-black);;
        }

        & > .cmp-navigation__group {
          display: flex;
          animation: fadeIn 0.2s ease-in-out;
          flex-direction: column;
          position: sticky;
        }
      }
    }
  }

  & .cmp-navigation__group--level-2 {
    display: none;
    width: 100%;
    position: absolute;
    background: var(--cet-color-neutral-black);
    padding-bottom: 0.625rem;
    cursor: default;
    top: auto;
    left: 0;

    & .cmp-navigation__group {
      position: static;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background-color: var(--cet-color-neutral-80);

      @media screen and (min-width: 1024px) {
        border-radius: 0.25rem;
        width: auto;
      }
    }

    & p {
      font-size: var(--cet-font-size-caption-2);
      font-weight: 500;
      line-height: 1.125rem;

      @media screen and (min-width: 600px) {
        max-width: 300px;
      }
    }

    & a {
      padding: var(--cet-space-xs);
      display: block;
      color: var(--cet-color-neutral-white);
      text-decoration: none;

      @media screen and (min-width: 1024px) {
        padding: var(--cet-space-xs) var(--cet-space-l);
      }
    }

    &-visible {
      display: flex;
    }

    @media screen and (min-width: 1024px) {
      padding: var(--cet-space-s) var(--cet-space-s) var(--cet-space-s) 0;
      width: auto;
      top: 100%;
      border-radius: 0 0 0.25rem 0;
    }

    .cmp-navigation__item--level-2 {
      & > .cmp-navigation__item-link {
        font-family: var(--cet-font-montreal), sans-serif;
        font-size: var(--cet-font-size-body-2);
        font-weight: 700;
        line-height: var(--cet-line-height);
      }

      &:hover {
        text-decoration: underline .1rem var(--cet-color-accent-100);
      }
    }
  }
}
