:root {
  --cet-color-accent-100: hsla(72, 100%, 57%, 1);
  --cet-color-accent-80: hsla(71, 100%, 75%, 1);
  --cet-color-accent-40: hsla(72, 100%, 87%, 1);
  --cet-color-accent-10: hsla(72, 100%, 92%, 1);

  --cet-color-highlight-100: hsla(211, 100%, 41%, 1);
  --cet-color-highlight-50: hsla(196, 100%, 85%, 1);
  --cet-color-highlight-10: hsla(185, 70%, 94%, 1);

  // DEFER TODO: update to #151515 PI25.2
  --cet-color-neutral-black: #000;
  --cet-color-neutral-90: hsla(0, 0%, 15%, 1);
  --cet-color-neutral-80: hsla(0, 0%, 22%, 1);
  --cet-color-neutral-60: hsla(0, 0%, 40%, 1);
  --cet-color-neutral-50: hsla(0, 0%, 70%, 1);
  --cet-color-neutral-40: hsla(0, 0%, 88%, 1);
  --cet-color-neutral-20: hsla(0, 0%, 92%, 1);
  --cet-color-neutral-white: #fff;

  --cet-color-error-100: hsla(4, 76%, 40%, 1);
  --cet-color-error-10: hsla(4, 80%, 96%, 1);

  --cet-color-warning-100: hsla(38, 89%, 42%, 1);
  --cet-color-warning-10: hsla(38, 89%, 90%, 1);

  --cet-color-success-100: hsla(120, 38%, 35%, 1);
  --cet-color-success-10: hsla(120, 40%, 94%, 1);

  --cet-color-info-100: hsla(192, 63%, 37%, 1);
  --cet-color-info-10: hsla(192, 61%, 92%, 1);

  --cet-color-backdrop-light: hsla(0, 0%, 42%, 0.75);
  --cet-color-backdrop-medium: hsla(0, 0%, 0%, 0.75);
  --cet-color-backdrop-dark: hsla(0, 0%, 0%, 0.6);

  --cet-color-overlay-light: hsla(0, 0%, 92%, 0.7);

  --cet-box-shadow-card: 2px 2px 32px 2px hsla(220, 43%, 11%, 0.05);
  --cet-box-shadow-vehicle-card: 0px 4px 4px 0px hsla(0, 0%, 0%, 0.25);
  --cet-box-shadow-vehicle-card-focus: 0 0 0 2px var(--cet-color-highlight-100),
  0 0 0 4px #fff, 0px 4px 4px 6px hsla(0, 0%, 0%, 0.25);
  --cet-box-shadow-focus: 0 0 0 2px var(--cet-color-highlight-100), 0 0 0 4px #fff, 0 0 0 6px var(--cet-color-highlight-100);


  --cet-border-radius: 2px;

  --cet-screen-md: 768px;
  --cet-screen-lg: 1024px;

  --cet-max-width: 80rem;

  --cet-font-akzidenz: akzidenz, 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial;
  --cet-font-montreal: montreal, 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial;
  --cet-font-plexmono: plexmono, 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial;

  --cet-line-height: 1.5;
  --cet-line-height-title-1: 1.25;
  --cet-line-height-label-1: 1.75;
  --cet-line-height-label-2: 1.5;
  --cet-line-height-label-3: 1.714;
  --cet-line-height-caption-1: 1.714;

  --cet-font-size-title-1: 2rem;
  --cet-font-size-title-1-lg: 3.125rem;
  --cet-font-size-title-2: 1.5rem;
  --cet-font-size-title-2-lg: 2rem;
  --cet-font-size-title-3: 1.25rem;
  --cet-font-size-title-3-lg: 1.5rem;

  --cet-font-size-st-1: 2rem;
  --cet-font-size-st-1-lg: 3rem;
  --cet-font-size-st-2: 2rem;
  --cet-font-size-st-2-lg: 2.625rem;
  --cet-font-size-st-3: 1.5rem;
  --cet-font-size-st-3-lg: 1.875rem;
  --cet-font-size-st-4: 1.375rem;
  --cet-font-size-st-4-lg: 1.5rem;

  --cet-font-size-body-1: 1.25rem;
  --cet-font-size-body-2: 1rem;

  --cet-font-size-caption-1: 0.875rem;
  --cet-font-size-caption-2: 0.75rem;

  --cet-font-size-label-1: 1.125rem;
  --cet-font-size-label-2: 1rem;
  --cet-font-size-label-3: 0.875rem;

  --cet-font-size-technical: 1rem;

  --cet-space-4xs: 0.25rem;
  --cet-space-3xs: 0.5rem;
  --cet-space-2xs: 0.75rem;
  --cet-space-xs: 1rem;
  --cet-space-s: 1.25rem;
  --cet-space-m: 1.5rem;
  --cet-space-l: 2rem;
  --cet-space-xl: 3rem;
  --cet-space-2xl: 4rem;
  --cet-space-3xl: 6rem;
}

//== Defaults

html,
body {
  font-size: 100%;
}

html,
body {
  background: $container-container-white;
  color: $text-text-dark;
  font-family: $font-family-copy;
  line-height: $font-height;
  margin: 0;
  height: 100%;
}

._js-overflow-hidden {
  overflow: hidden;
}

a {
  color: $color-link;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {

  @include generate-grid(default, $max_col);
}

// Phone breakpoint
@media (max-width: $breakpoint-mobile-old) {
  .aem-Grid {

    @include generate-grid(phone, $max_col);
  }
}

%sr-only,
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

%not-sr-only,
.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.cet-mobile-only {
  @media screen and (min-width: 481px) {
    display: none !important;
  }
}

.cet-tablet-only {
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    display: inline-flex !important;
  }
}

.cet-mobile-tablet {
  @media screen and (min-width: 1024px) {
    display: none !important;
  }
}

.cet-desktop-only {
  display: none !important;

  @media screen and (min-width: 1024px) {
    display: inline-flex !important;
  }
}
