.teaser--stage {
  &.teaser {
    background-color: $container-container-black;
    color: $text-text-light;

    a {
      color: $text-text-light;
    }

    .container--bg-white & { 
      background-color: $container-container-white;
      color: $text-text-dark;
  
      a {
        color: $text-text-dark;
      }
    }

    .cmp-teaser {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-bottom: 2rem;
      padding-top: 8rem;
      position: relative;
      z-index: 1;
    }

    .cmp-teaser__content {
      margin: 0 auto;
      max-width: $default-container-width;
      padding: 4rem $primitives-size-200;
      z-index: 1;
      width: 100%;
      box-sizing: border-box;

      p {
        max-width: 58.5rem;
        font-size: $primitives-size-300;
      }
    }

    .cmp-teaser__description {
      font-size: $font-size-p-big-mobile;
      line-height: $line-height-p-big-mobile;

      @media screen and (min-width: $breakpoint--desktop) {
          font-size: $font-size-p-big-desktop;
          line-height: $line-height-p-big-desktop;   
      }

    }

    .cmp-teaser__image {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    .cmp-image {
      height: 100%;
      margin-top: 0;
      width: 100%;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .cmp-teaser__title {

      @extend %h1;
      color: $text-text-green;
      margin-bottom: 0;

      .container--bg-white & {
        background-color: $container-container-green;
        color: $text-text-dark;
        font-family: $font-family-copy;
        font-size: 3rem;
        font-weight: 500;
        line-height: 4.5rem;
        margin-left: auto;
        margin-right: auto;
        padding: 0.5rem 1.5rem;
        position: relative;
        width: fit-content;

        &:after {
          background-color: $container-container-green;
          content:'';
          height: 100%;
          left: -50vw;
          position: absolute;
          top: 0;
          width: 50vw;
        }
      }
    }

    .cmp-teaser__title-link {
      text-decoration: none;
    }

    .cmp-teaser__action-container {
      .cmp-teaser__action-link:first-child {

        @extend %cta;
        @extend %cta--primary;
        .container--bg-white & {

          @extend %cta--primary-inverted;
        }
      }
    }

    .cmp-teaser__action-link + .cmp-teaser__action-link {

      @extend %cta;
      @extend %cta--secondary;

      .container--bg-white & {

        @extend %cta--secondary-inverted;
      }
    }
  }
}
