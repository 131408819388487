.cmp-image {
}
.cmp-image__link {
}
.cmp-image__image {
  display: block;
}
.cmp-image__title {
}

.image {

  &.image--size-width-height-auto {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cmp-image {
      width:fit-content;
      height: fit-content;
    }

    .cmp-image__image {
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }

  &.image--space-bottom-800  {
    margin-bottom: $primitives-size-800;
  }

  &.image--space-bottom-400 {
    margin-bottom: $primitives-size-400;
  }

  &.image--space-bottom-0  {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.image--space-top-800 {
    margin-top: $primitives-size-800;
  }

  &.image--space-top-400 {
    margin-top: $primitives-size-400;
  }

  &.image--space-top-0 {
    margin-top: 0;
    padding-top: 0;
  }

  &.image--border-bottom-highlight {
    .cmp-image__image {
      border-bottom: $primitives-size-150 solid $borders-border-green;
    }
  }


}
