.product-overview {
    margin: 0 auto;
    max-width: $default-container-width;
    padding: 4rem $primitives-size-200;

    .cmp-image {
        margin-top: 0;
        aspect-ratio: 2 / 1;
        overflow: hidden;

        img {
            border-radius: 1.25rem 1.25rem 0 0;
        }
    }

    .cmp-list {
      display: flex;
      flex-wrap: wrap;
      gap: $primitives-size-400;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
  }
    .cmp-list__item {
        border-radius: 1.25rem;
        box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.10);
        margin: 0;
        min-width: 340px;
        padding: 0;
        width: 100%;

        @media screen and (min-width: $breakpoint--tablet) {
            width: calc(50% - 1.4rem);
        }

        @media screen and (min-width: $breakpoint--desktop) {
            width: calc(33% - 1.4rem);
        }
    }

    .cmp-teaser {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        cursor: pointer;
    }

    .cmp-teaser__content {
        height: 100%;
        padding: 2rem 1.5rem 1rem;
    }

    .cmp-teaser__description {
        margin-bottom: $primitives-size-400;
    }

    .teaser__tags {
        margin-top: 0;
        border-bottom: 1px solid $borders-border-light;
    }

    .teaser__tags span {
        border-color: $borders-border-black;
        margin-right: 0;
    }

    .cmp-teaser__link {
        margin: 0 1.5rem 2rem;
        @extend %cta;
        @extend %cta--primary-inverted;
        z-index: 2;
    }

    .cmp-teaser__hidden-link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
}