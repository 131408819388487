.cet-cmp-link {
  @extend %cet-cta;
  text-decoration: none;
}

.cet-cmp-link--primary {
  @extend %cet-cta--primary;
}

.cet-cmp-link--primary.cet-cmp-link--inverted {
  @extend %cet-cta--primary-inverted;
}

.cet-cmp-link--secondary {
  @extend %cet-cta--secondary;
}
.cet-cmp-link--secondary.cet-cmp-link--inverted {
  @extend %cet-cta--secondary-inverted;
}

.cet-cmp-link--tertiary {
  @extend %cet-cta--tertiary;
}

.cet-cmp-link--tertiary.cet-cmp-link--inverted {
  @extend %cet-cta--tertiary--inverted;
}

.cet-cmp-link--small {
  @extend %cet-cta--small;
}
.cet-cmp-link--small.cet-cmp-link--tertiary {
  padding: 0 0.125rem;
}

.cet-cmp-link--medium {
  @extend %cet-cta--medium;
}

.cet-cmp-link--medium.cet-cmp-link--tertiary {
  padding: 0;
}

.cet-cmp-link--large {
  @extend %cet-cta--large;
}

.cet-cmp-link--large.cet-cmp-link--tertiary {
  padding: 0;
}

.cet-cmp-link--large svg,
.cet-cmp-link--medium.cet-cmp-link--icon svg,
.cet-cmp-link--large.cet-cmp-link--icon svg {
  width: 1.5rem;
  height: 1.5rem;
}

.cet-cmp-link--small.cet-cmp-link--icon svg {
  width: 1.25rem;
  height: 1.25rem;
}

.cet-cmp-link--small.cet-cmp-link--icon {
  padding: 0.375rem;
}

.cet-cmp-link--medium.cet-cmp-link--icon {
  padding: 0.5rem;
}

.cet-cmp-link--large.cet-cmp-link--icon {
  padding: 0.75rem;
}
