.button--standalone {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.button--left {
    text-align: left;
    align-items: flex-start;
  }


  &.button--right {
    text-align: center;
    align-items: center;

    @media screen and (min-width: $breakpoint--desktop) {
      text-align: right;
      align-items: flex-end;
    }
  }
  &.button--center {
    text-align: center;
    align-items: center;
  }

  .text--standalone + & {
    padding-top: 0;
  }
}

.button {
  &.cta {
    .cmp-button {
      @extend %cta;
    }
  }
  &.cta--primary {
    .cmp-button {
      @extend %cta--primary;
    }
  }
  &.cta--primary-inverted {
    .cmp-button {
      @extend %cta--primary-inverted;
    }
  }
  &.cta--secondary {
    .cmp-button {
      @extend %cta--secondary;
    }
  }
  &.cta--secondary-inverted {
    .cmp-button {
      @extend %cta--secondary-inverted;
    }
  }
  .cmp-form-button {
    @extend %cta;
    @extend %cta--primary-inverted;
    margin-right: 0.5em;
  }
}

.cmp-button {
  @extend %cta;

  div:hover & {
    cursor: pointer;
  }
}
.cmp-button__text {
  font-weight: 300;
  text-decoration: none;
}

// CET newish buttons classes with namespace prefix "cet"
.cet-cmp-button {
  @extend %cet-cta;
}

.cet-cmp-button--primary {
  @extend %cet-cta--primary;
  &:disabled {
    background: var(--cet-color-neutral-20);
    color: var(--cet-color-neutral-50);
  }
}

.cet-cmp-button--primary.cet-cmp-button--inverted {
  @extend %cet-cta--primary-inverted;
  &:disabled {
    background: var(--cet-color-neutral-60);
    color: var(--cet-color-neutral-80);
  }
}

.cet-cmp-button--secondary {
  @extend %cet-cta--secondary;
  &:disabled {
    color: var(--cet-color-neutral-50);
  }
}
.cet-cmp-button--secondary.cet-cmp-button--inverted {
  @extend %cet-cta--secondary-inverted;
  &:disabled {
    color: var(--cet-color-neutral-60);
  }
}

.cet-cmp-button--tertiary {
  @extend %cet-cta--tertiary;
}

.cet-cmp-button--tertiary.cet-cmp-button--inverted {
  @extend %cet-cta--tertiary--inverted;
  &:disabled {
    color: var(--cet-color-neutral-60);
  }
}

.cet-cmp-button--small {
  @extend %cet-cta--small;
}

.cet-cmp-button--small.cet-cmp-button--tertiary {
  padding: 0 0.125rem;
}

.cet-cmp-button--medium {
  @extend %cet-cta--medium;
}

.cet-cmp-button--medium.cet-cmp-button--tertiary {
  padding: 0;
}

.cet-cmp-button--large {
  @extend %cet-cta--large;
}

.cet-cmp-button--large.cmp-button--tertiary {
  padding: 0;
}

.cet-cmp-button--large svg,
.cet-cmp-button--medium.cet-cmp-button--icon svg,
.cet-cmp-button--large.cet-cmp-button--icon svg {
  width: 1.5rem;
  height: 1.5rem;
}

.cet-cmp-button--small.cet-cmp-button--icon svg {
  width: 1.25rem;
  height: 1.25rem;
}

.cet-cmp-button--small.cet-cmp-button--icon {
  padding: 0.375rem;
}

.cet-cmp-button--medium.cet-cmp-button--icon {
  padding: 0.5rem;
}

.cet-cmp-button--large.cet-cmp-button--icon {
  padding: 0.75rem;
}
