.cet-cmp-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // 1.5rem 1.25rem by design
  padding: var(--cet-space-m) var(--cet-space-s);
  border-radius: 1rem;
  box-shadow: var(--cet-box-shadow-card);
  height: 100%;
  box-sizing: border-box;
}

.cet-cmp-card--light {
  color: var(--cet-color-neutral-black);
  background: var(--cet-color-neutral-white);
}

.cet-cmp-card--dark {
  color: var(--cet-color-neutral-white);
  background: var(--cet-color-neutral-80);
}

.cet-cmp-card__icon-container {
  position: relative;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  background-color: var(--cet-color-accent-100);
  color: var(--cet-color-neutral-black);
  margin-block-end: var(--cet-space-s);
}

.cet-cmp-card__icon-container svg {
  margin: 0.625rem;
}

.cet-cmp-card__icon-container img {
  position: absolute;
  top:0;
  right:0;
  bottom: 0;
  left:0;
  padding: 0.625rem;
  max-width: 100%;
  max-height: 100%;
}

.cet-cmp-card__content {
  margin-block-start: var(--cet-space-s);
}

// Override user agent spacing and not to collide with GIS legacy styles
.cet-cmp-card__content p {
  margin: 0;
}
.cet-cmp-card__content p + p {
  margin-block-start: var(--cet-space-s);
}

.cmp-card__contact-person {
  margin-block-start: var(--cet-space-s);
}

.cet-cmp-card__cta {
  margin-block-start: var(--cet-space-m);
}

// Parsys spacing
.cet-cmp-card__content ~ *:not(.cet-cmp-card__cta, .cet-cmp-card__contact-person) {
  margin-block-start: var(--cet-space-m);
}

// Auto spacing for last element on the second last element,
// so that the set minimum spacing is not overridden
.cet-cmp-card > *:nth-last-child(2) {
  margin-bottom: auto;
}


// Contact person as own block within a card
.cet-cmp-card-contact-person {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--cet-space-2xs);
}

.cet-cmp-card-contact-person__profile-image {
  aspect-ratio: 1 / 1;
  width: 7rem;
  border-radius: 50%;
}

.cet-cmp-card-contact-person dl {
  margin: 0;
  font-size: var(--cet-font-size-body-1);
  line-height: var(--cet-line-height);
}

.cet-cmp-card-contact-person dd {
  margin: 0;
}

.cet-cmp-card-contact-person__first-name,
.cet-cmp-card-contact-person__last-name {
  display: inline-block;
}

.cet-cmp-card-contact-person__role {}
