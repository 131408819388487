.gis-form-container {
  margin: 0 auto $primitives-size-600;
  max-width: $default-container-width;
  padding: $primitives-size-100 $primitives-size-200;
  
  .container--space-cage & {
    padding-left: 0;
    padding-right: 0;
  }

  .button {
    margin-top: $primitives-size-400;
  }

  .cmp-form {
    max-width: 50rem;
  }

  &.container--space-top-400 {
    margin-top: 0;
    padding-top: $primitives-size-400;
  }

  &.container--space-bottom-400 {
    margin-bottom: 0;
    padding-bottom: $primitives-size-400;
  }

  &.container--space-top-800 {
    margin-top: 0;
    padding-top: $primitives-size-800;
  }

  &.container--space-bottom-800 {
    margin-bottom: 0;
    padding-bottom: $primitives-size-800;
  }

  &.container--space-bottom-0 {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.container--space-top-0 {
    margin-top: 0;
    padding-top: 0;
  }


  .cmp-form-error {
    background-color: $functional-error-light;
    color: $functional-error;
    margin-bottom: $primitives-size-400;
    padding: $primitives-size-100 $primitives-size-200;
  }

  .cmp-form-error__item {
    margin-bottom: 0;
  }

  div[data-cmp-infoicon-message] {
    position: relative;

    input, textarea {
      padding-right: 2.5rem;
    }

    svg {
      position: absolute;
      right: 0.5rem;
      top: 2.2rem;
    }
  }

  .infoicon-message {
    background: $container-container-grey;
    border-radius: 0.1875rem;
    color: $text-text-light;
    padding: 1.25rem;
    max-width: 12.5rem;
    position: absolute;
    right: 0;
    top: 5.5rem;
    z-index: 4000;
  }
}

// TODO (E3AUDEDH-1816): replace by better spam protection
// Hide a focusable input field for spam bots to fill on.
.gis-form-container {
  .cmp-form-text:has([name="foo-bar-address"]) {
    @extend %sr-only;
  }
}
