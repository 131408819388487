.cmp-diagnostics__download-link {
    @extend %cta;
    @extend %cta--primary-inverted;
    position: relative;
    left: -100vw;

    &.is-visible {
        left: 0;
    }
}

.cmp-diagnostics__form-input-container {
    label {
        display: block;
        margin-bottom: 0.5rem;
    }
}

.cmp-diagnostics__input {
    margin-bottom: 1rem;
    max-width: 100%;
    padding: 0.5rem;
    width: 18rem;
}

.cmp-diagnostics__item-icon {
    display: none;
}