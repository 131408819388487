%cta {
    -webkit-user-drag: none;
    align-items: center;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    display: inline-flex;
    flex-shrink: 0;
    font-family: $font-family-title;
    font-size: 1rem;
    height: fit-content;
    justify-content: center;
    line-height: 150%;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    padding-bottom: 0.625rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.625rem;
    text-align: center;
    text-decoration: none;
    user-select: none;
    width: fit-content;
}

%cta--primary {
    background-color: $container-container-green;
    border-color: $borders-border-green;
    color: $text-text-dark;

    &:hover {
      background-color: $container-container-darkgreen;
      border-color: $borders-border-darkgreen;
      color: $text-text-dark;
  }
    &:focus {
      background-color: $container-container-darkgreen;
      border-color: $borders-border-darkgreen;
      box-shadow: 0 0 0 0.125em $container-container-darkgreen;
      outline: none;
  }
    &:disabled {
      background-color: $container-container-light-grey;
      border-color: $borders-border-light;
      color: $text-text-dark;

      & .cmp-button__text {
        color: $text-text-dark;
    }
  }
}

%cta--primary-inverted {
  background-color: $container-container-black;
  border-color: $borders-border-black;
  color: $text-text-green;

  &:hover {
    background-color: $container-container-black;
    border-color: $borders-border-black;
    color: $text-text-darkgreen;
}
  &:focus {
    background-color: $container-container-black;
    border-color: $borders-border-black;
    box-shadow: 0 0 0 0.125em $container-container-darkgreen;
    color: $text-text-green;
    outline: none;
}
  &:disabled {
    background-color: $container-container-light-grey;
    border-color: $borders-border-light;
    color: $text-text-dark;

    & .cmp-button__text {
      color: $text-text-dark;
  }
}
}

  %cta--secondary {
    background-color: $container-container-white;
    border-color: $borders-border-light;
    color: $text-text-dark;

    &:hover {
      background-color: $container-container-black;
      border-color: $borders-border-black;
      color: $text-text-light;
  }
    &:focus {
      background-color: $container-container-black;
      border-color: $borders-border-black;
      box-shadow: 0 0 0 0.125em $container-container-black;
      color: $text-text-light;
      outline: none;
  }
    &:disabled {
      background-color: $container-container-light-grey;
      border-color: $borders-border-light;
      color: $text-text-dark;

      & .cmp-button__text {
        color: $text-text-dark;
    }
  }
}

%cta--secondary-inverted {
  background-color: $container-container-black;
  border-color: $borders-border-black;
  color: $text-text-light;

  &:hover {
    background-color: $container-container-white;
    border-color: $borders-border-light;
    color: $text-text-dark;
}
  &:focus {
    background-color: $container-container-black;
    border-color: $borders-border-black;
    box-shadow: 0 0 0 0.125em $container-container-black;
    color: $text-text-light;
    outline: none;
}
  &:disabled {
    background-color: $container-container-light-grey;
    border-color: $borders-border-light;
    color: $text-text-dark;

    & .cmp-button__text {
      color: $text-text-dark;
  }
}
}


// CET placeholder classes for cet-cmp-button or cet-cmp-link
%cet-cta {
  display: inline-flex;
  align-items: center;
  font-family: var(--cet-font-akzidenz), sans-serif;
  font-weight: bold;
  gap: 0.5rem;
  border: 0;
  border-radius: 2px;
  cursor: pointer;
  outline: 0;

  &:focus-visible {
    box-shadow: var(--cet-box-shadow-focus);
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
}

%cet-cta--primary {
  background: var(--cet-color-neutral-black);
  color: var(--cet-color-accent-100);

  &:hover {
    background: var(--cet-color-neutral-80);
    color: var(--cet-color-accent-100);
  }
}

%cet-cta--primary-inverted {
  background: var(--cet-color-accent-100);
  color: var(--cet-color-neutral-black);

  &:hover {
    background: var(--cet-color-accent-10);
    color: var(--cet-color-neutral-black);
  }
}

%cet-cta--secondary {
  background: transparent;
  color: var(--cet-color-neutral-black);
  border: 1px solid currentColor;

  &:hover {
    background: var(--cet-color-accent-10);
    color: var(--cet-color-neutral-black);
    outline: 1px solid currentColor;
    outline-offset: -2px;
  }
  &:focus-visible {
    outline: 0;
  }
}

%cet-cta--secondary-inverted {
  color: var(--cet-color-neutral-white);

  &:hover {
    background: var(--cet-color-neutral-80);
    color: var(--cet-color-neutral-white);
  }
}

%cet-cta--tertiary {
  color: var(--cet-color-highlight-100);
  text-underline-offset: 2px;
  background: transparent;

  &:hover {
    text-decoration: underline;
  }
}

%cet-cta--tertiary--inverted {
  color: var(--cet-color-accent-100);

  &:hover {
    color: var(--cet-color-accent-10);
  }
}

%cet-cta--small {
  font-size: var(--cet-font-size-label-3);
  line-height: var(--cet-line-height-label-3);
  padding: 0.25rem 1rem;
}

%cet-cta--medium {
  font-size: var(--cet-font-size-label-2);
  line-height: var(--cet-line-height-label-2);
  padding: 0.375rem 1rem;
}

%cet-cta--large {
  font-size: var(--cet-font-size-label-1);
  line-height: var(--cet-line-height-label-1);
  padding: 0.5rem 1.25rem;
}
