.apispecification {


    .swagger-ui .wrapper {
        box-sizing: border-box;
        margin: 0 0;
        max-width: $max-text-width;
        padding: 0;
        width: 100%;
    }

    .swagger-ui .info {
        margin: 1rem 0;

        .title {
            @extend %h2;
            line-height: initial;
        }

        .description {
            display: none;
        }
    }

    .swagger-ui .scheme-container {
        box-shadow: none;
    }

    .swagger-ui .info hgroup.main a {
        display: none;
    }


    .swagger-ui .auth-wrapper,
    .swagger-ui .try-out,
    .authorization__btn {
        display: none;
    }

    .swagger-ui .auth-btn-wrapper {
        justify-content: flex-end;
    }

    .swagger-ui .scheme-container .schemes .auth-wrapper .authorize {
        margin-right: $primitives-size-100;
    }

    .swagger-ui .model {
        font-family: monospace;
        font-size: 0.75rem;

    }
    .swagger-ui .renderedMarkdown {
        p {
            font-size: 0.75rem;
        }
       
    }
}