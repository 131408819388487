// The fonts were generated using fontpie (https://github.com/pixel-point/fontpie),
// a tool that converts font files into web-compatible formats and generates the necessary CSS code.

@font-face {
  font-family: 'montreal';
  src: url('./resources/fonts/PPNeueMontreal-Regular.woff2') format('woff2'),
    url('./resources/fonts/PPNeueMontreal-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montreal';
  src: url('./resources/fonts/PPNeueMontreal-Bold.woff2') format('woff2'),
    url('./resources/fonts/PPNeueMontreal-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'akzidenz';
  src: url('./resources/fonts/akzidgrostdbolext-webfont.woff2') format('woff2'),
    url('./resources/fonts/akzidgrostdbolext-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'plexmono';
  src: url('./resources/fonts/IBMPlexMono-Bold.woff2') format('woff2'),
    url('./resources/fonts/IBMPlexMono-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
