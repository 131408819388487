.main-footer {
  background-color: $container-container-black;
  width: 100%;

  .cmp-experiencefragment {
    margin: 0 auto;
    max-width: $default-container-width;
    padding: $primitives-size-800 $primitives-size-200 $primitives-size-600 $primitives-size-200;

    > .cmp-container {
      
      > .container > .cmp-container {
        display: grid;
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .text { 
          grid-area: 3 / 1 / 4 / 2; 
          padding-right: 1rem;
        }
        .text + .container { 
          grid-area: 1 / 1 / 2 / 2; 
          padding-bottom: $primitives-size-400;
          padding-right: 1rem;
        }
        .container + .container { 
          grid-area: 2 / 1 / 3 / 2; 
          padding-bottom: $primitives-size-400;
        } 

        @media screen and (min-width: $breakpoint--tablet) {
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 1fr;
          justify-items: left;
          align-items: baseline;

          .text { 
            grid-area: 1 / 1 / 2 / 2; 
          }
          .text + .container { 
            grid-area: 1 / 2 / 2 / 3;
            padding-bottom: 0;
          }
          .container + .container { 
            grid-area: 1 / 3 / 2 / 4; 
            padding-bottom: 0;
          } 

        }
      }
    }
  }

  .cmp-text {
    color: $text-text-grey;
    font-family: 'montreal';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-top: 0;
    max-width: 100%;
    padding: 0;
    width: 100%;

    p {
      font-size: 1rem;
    }
  }

  .cmp-title { 
    color: $text-text-grey;
    margin: 0;
    padding: 0;

    .cmp-title__text {
      font-family: 'montreal';
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin-bottom: 1rem;
      text-transform: none;
    }
  }  

  .list {
    ul {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      list-style: none;
      margin: 0;
      padding: 0;
    }
    
    li {
      margin-bottom: 0.75rem;
    }

    a {
      color: $text-text-light;
      font-family: 'akzidenz';
      text-decoration: none;
    }
  }

}

// #main-footer {
//   margin-top: 5em;
//   padding: 1.625em 0 1.5625em 0;
//   border-top: 0.125em solid $color-shade-1;

//   .h6_style .cmp-title__text {
//     margin-left: 5rem;
//   }

//   .separator {
//     display: none;
//   }

//   & .cmp-container {
//     display: flex;
//   }

//   .image,
//   .text {
//     margin: 0 1em;
//   }

//   & .cmp-image {
//     margin: 0;
//   }

//   .cmp-image__image {
//     display: block;
//     width: 9.2em;
//     margin: 0 auto;
//   }

//   .cmp-title {
//     margin: 0.5em 1em;
//     text-align: center;
//     @media only screen and (max-width: $breakpoint-mobile-old) {
//       margin: 1em 1em;
//     }
//   }

//   .cmp-text {
//     margin: 0;
//     text-align: center;
//     & p {
//       margin-block: 0;
//       & a {
//         color: $color-accent;
//         text-decoration: none;
//         white-space: nowrap;
//         &:focus {
//           color: $color-accent-lighter;
//           border: 0.125em solid $color-accent-lighter;
//           border-radius: 0.25em;
//         }
//         &:hover {
//           color: $color-accent-darker;
//         }
//       }
//     }
//   }

//   //=================== Media queries ===================

//   @media (max-width: $breakpoint-mobile-old) {
//     margin-top: 0;
//   }
// }

// @media (max-width: $breakpoint-mobile-old) {
//   #main-footer .h6_style .cmp-title__text {
//     margin-left: 0;
//   }
// }
