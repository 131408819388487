
//== XF Header style, used on page template

header {
  position: sticky;
  width: 100%;
  top: 0;
  background: var(--cet-color-neutral-black);
  z-index: 1000;

  .cmp-navigation__group {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
  }

  .cmp-navigation__item-link {
    font-family: var(--cet-font-akzidenz), sans-serif;
    font-weight: bold;
    font-size: var(--cet-font-size-label-2);
    line-height: var(--cet-line-height-label-2);
    color: var(--cet-color-neutral-white);
    text-decoration: none;
  }

  //== Language Navigation
  .cmp-languagenavigation {
    position: relative;
  }

  .cmp-languagenavigation__group {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .cmp-languagenavigation__item {
    display: flex;
    gap: var(--cet-space-3xs);
    align-items: center;
    font-size: var(--cet-font-size-caption-1);
    line-height: var(--cet-font-size-caption-1);

  }

  .cmp-languagenavigation__item--level-0 {
    padding: var(--cet-space-s) var(--cet-space-xs);
    &::before {
      content: "";
      color: var(--cet-color-neutral-white);
      display: block;
      height: 24px;
      width: 24px;
      background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
    @media screen and (min-width: 1024px) {
      padding: 0

    }
  }
  .cmp-languagenavigation__item-title {
    font-weight:bold;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
