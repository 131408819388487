.teaser--hero {
  &.teaser {
    background-color: $container-container-black;
    color: $text-text-light;

    a {
      color: $text-text-light;
    }

    .container--bg-white & { 
      background-color: $container-container-white;
      color: $text-text-dark;
  
      a {
        color: $text-text-dark;
      }
    }

    .cmp-teaser {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      min-height: 20.625rem;
      position: relative;
      z-index: 1;

      .container_pre_content & {
        min-height: 37.8125rem;
      }
    }

    .cmp-teaser__content {
      margin: 0 auto;
      max-width: $default-container-width;
      padding: 8rem $primitives-size-200 4rem;
      z-index: 1;
      width: 100%;
      box-sizing: border-box;

      p {
        max-width: 58.5rem;
      }
    }

    .cmp-teaser__image {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    .cmp-image {
      height: 100%;
      margin-top: 0;
      width: 100%;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .cmp-teaser__title {

      @extend %h1;

      .container--bg-white & {
        background-color: $container-container-green;
        color: $text-text-dark;
        font-family: $font-family-copy;
        font-size: 3rem;
        font-weight: 500;
        line-height: 4.5rem;
        margin-left: auto;
        margin-right: auto;
        padding: 0.5rem 1.5rem;
        position: relative;
        width: fit-content;

        &:after {
          background-color: $container-container-green;
          content:'';
          height: 100%;
          left: -50vw;
          position: absolute;
          top: 0;
          width: 50vw;
        }
      }
    }

    .cmp-teaser__title-link {
      text-decoration: none;
    }

    .cmp-teaser__action-container {
      .cmp-teaser__action-link:first-child {

        @extend %cta;
        @extend %cta--primary;
        .container--bg-white & {

          @extend %cta--primary-inverted;
        }
      }
    }

    .cmp-teaser__action-link + .cmp-teaser__action-link {

      @extend %cta;
      @extend %cta--secondary;

      .container--bg-white & {

        @extend %cta--secondary-inverted;
      }
    }
  }

  &.teaser--hero-product {
    .cmp-teaser__title {
      color: $text-text-green;
      text-transform: none;
    }

    &.teaser--title-capitalize {
      .cmp-teaser__title {
        text-transform: uppercase;
      }
    }
  }

  .teaser__tags {
    margin: $primitives-size-600 0;
    span {
      border: 2px solid $container-container-white;
    }
  }

  @media screen and (min-width: $breakpoint--desktop) {

    .cmp-teaser__title,
    .cmp-teaser__description {
      font-size: 1.5rem;
      max-width: 75%;
    }

    .cmp-teaser__description {
      font-size: 1.5rem;
      p {
        font-size: 1.5rem;
      }
    }

  }
}
