.accordion {
  .cmp-text {
    margin-bottom: var(--cet-space-m);
    margin-top: 0;
  }
}

.cmp-accordion__title {
  font-family: var(--cet-font-montreal), sans-serif;
  font-weight: 700;

  @media screen and (min-width: 900px) {
    font-size: var(--cet-font-size-title-2);
  }
}

.cmp-accordion__panel {
  padding: var(--cet-space-3xs) var(--cet-space-xs);
}

.cmp-accordion__icon {
  margin-left: var(--cet-space-xs);
}

.cmp-accordion__button {
  border: var(--cet-border-radius) solid transparent;
  display: flex;
  justify-content: space-between;
  outline: none;
  padding: var(--cet-space-3xs);

  .cmp-accordion__icon--collapsed {
    display: flex;
  }
  .cmp-accordion__icon--expanded {
    display: none;
  }

  &:focus-visible {
    box-shadow: var(--cet-box-shadow-focus);
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 900px) {
    padding: var(--cet-space-l) var(--cet-space-3xs);
  }
}

.cmp-accordion__button--expanded {
  outline: none;

  .cmp-accordion__icon--collapsed {
    display: none;
  }
  .cmp-accordion__icon--expanded {
    display: flex;
  }
}

.cmp-accordion--dark {
  .cmp-accordion__title {
    color: var(--cet-color-neutral-white);
  }

  .cmp-text {
    color: var(--cet-color-neutral-white);
  }

  .cmp-accordion__item {
    border-bottom: 0.0625rem solid var(--cet-color-neutral-40);
  }

  .cmp-accordion__icon {
    color: #B2EDFF;
  }

  .cmp-accordion__button {
    background-color: var(--cet-color-neutral-black);
  }

  .cmp-accordion__panel--expanded {
    background-color: var(--cet-color-neutral-black);
  }
}

.cmp-accordion--light {
  .cmp-accordion__title {
    color: var(--cet-color-neutral-black);
  }

  .cmp-text {
    color: var(--cet-color-neutral-black);
  }

  .cmp-accordion__button {
    background-color: var(--cet-color-neutral-white);
  }

  .cmp-accordion__item {
    border-bottom: 0.0625rem solid var(--cet-color-neutral-40);
  }

  .cmp-accordion__icon {
    color: var(--cet-color-highlight-100);
  }

  .cmp-accordion__panel--expanded {
    background-color: var(--cet-color-neutral-white);
    color: var(--cet-color-neutral-black);
  }
}
