.breadcrumb {
    display: none;
    font-size: 1.25rem;
    @media screen and (min-width: $breakpoint--desktop) {
        display: block;
      }
}
.cmp-breadcrumb__list {
    margin: 0 0 $primitives-size-300 0;
    padding: 0;
}
.cmp-breadcrumb__item {

}
.cmp-breadcrumb__item:after {
    content:"/";   
}

.cmp-breadcrumb__item:last-child {
    font-weight: 700;
    &:after {
        content:"";   
    }
}

.cmp-breadcrumb__item--active {
    span {
        text-decoration: none;
        padding: 0.5rem;
        border-radius: 4px;
    }
}
.cmp-breadcrumb__item-link {
    text-decoration: none;
    padding: 0.5rem;
    border-radius: 4px;

    &:hover {
        background-color: $container-container-black;
        text-decoration: none;
    }
}