.cmp-text {
    table {
        border-radius:  0.5rem;
        border: 1px solid $borders-border-light;
        box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.10);
    }
    th {
        background-color: $container-container-light-grey;
        font-weight: normal;
        padding: 1.38rem 1.5rem;
    }
    td {
        border-bottom: 1px solid $borders-border-light;
        padding: 1.25rem 1.5rem;
    }
}