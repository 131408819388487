.seperator {
  margin: 0 auto;
  max-width: $default-container-width;
  padding: 0 $primitives-size-200;

  .tabs & {
    width: 100%;
    padding: 0;
  }
}

.separator.cq-Editable-dom {
  min-height: 1.5rem;
}

.cmp-separator__horizontal-rule {
  background-color: $container-container-light-grey;
  border: none;
  height: 1px;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  margin: $primitives-size-200 0;
}

.light {
  .cmp-separator__horizontal-rule { 
    background-color: $container-container-light-grey;
  }
}

.dark {
  .cmp-separator__horizontal-rule { 
    background-color: $container-container-black;
  }
}