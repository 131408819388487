.carousel {
  margin: 0 auto;
  max-width: $default-container-width;
  padding: 4rem $primitives-size-200;


  .container--space-cage & {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .cmp-image__image {
    max-height: 650px;
    max-width: 100%;
    width: auto;
  }
}

.cmp-carousel {
  margin: 0.5em;
}

.cmp-carousel__content {
  position: relative;
}

.cmp-carousel__item {
  margin: 0 auto;
  width: fit-content;
}

.cmp-carousel__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 40%;
  width: 100%;
}

.cmp-carousel__action {
  background-color: $container-container-green;
  border-radius: 0.25em;
  border-width: 0;
  box-shadow: none;
  cursor: pointer;
  height: 2.5rem;
  margin: 0.125em;
  position: relative;
  width: 2.5rem;

  & .cmp-carousel__action-icon {
    &:before {
      background-color: $container-container-black;
      content: "";
      display: inline-block;
      height: 24px;
      left: 50%;
      mask-size: cover;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
    }
  }
  &:focus {
    outline: none;
  }
}
.cmp-carousel__action--disabled {
}
.cmp-carousel__action--previous {
  & .cmp-carousel__action-icon {
    &:before {
      mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00ODUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDg1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNLjI5MywxMy4wMjFhMSwxLDAsMCwxLDAtMS40MTVsNC45NS00Ljk1TC4yOTMsMS43MDdBMSwxLDAsMCwxLDEuNzA3LjI5M0w3LjM2NCw1Ljk1YTEsMSwwLDAsMSwwLDEuNDE0TDEuNzA3LDEzLjAyMWExLDEsMCwwLDEtMS40MTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwMSA5OC42NTcpIHJvdGF0ZSgxODApIi8+PC9nPjwvc3ZnPg==");
    }
  }
}
.cmp-carousel__action--next {
  & .cmp-carousel__action-icon {
    &:before {
      mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDQ1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNMzAuNzA3LTMuNTY1YTEsMSwwLDAsMSwwLTEuNDE0bDQuOTQ5LTQuOTUtNC45NDktNC45NWExLDEsMCwwLDEsMC0xLjQxNCwxLDEsMCwwLDEsMS40MTQsMGw1LjY1Nyw1LjY1NmExLDEsMCwwLDEsLjI5My43MDcsMSwxLDAsMCwxLS4yOTMuNzA3TDMyLjEyMS0zLjU2NWExLDEsMCwwLDEtLjcwNy4yOTNBMSwxLDAsMCwxLDMwLjcwNy0zLjU2NVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQyMi45MjkgMTAxLjkyOSkiLz48L2c+PC9zdmc+");
    }
  }
}
.cmp-carousel__action--pause {
  display: none;
}
.cmp-carousel__action--play {
  display: none;
}
.cmp-carousel__action-icon {
}
.cmp-carousel__action-text {
  display: none;
}
.cmp-carousel__indicators {
  height: 20px;
}
.cmp-carousel__indicator {
  background-color: $container-container-light-grey;
  border-radius: 0.25rem;
  height: 0.5rem;
  margin: auto 0.25rem 0 0.25rem;
  width: 1.5rem;
}
.cmp-carousel__indicator--active {
  background-color: $container-container-black;
}
