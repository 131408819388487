.space-m--static {
  margin-block-start: var(--cet-space-m);
}

.space-l--static {
  margin-block-start: var(--cet-space-l);
}

.space-2xl--dyn {
  margin-block-start: var(--cet-space-m);

  @media screen and (min-width: 768px) {
    margin-block-start: var(--cet-space-2xl);
  }
}

.space-3xl--static {
  margin-block-start: var(--cet-space-3xl);
}

.space-3xl--dyn {
  margin-block-start: var(--cet-space-2xl);

  @media screen and (min-width: 768px) {
    margin-block-start: var(--cet-space-3xl);
  }
}
