.container.root {
  height: 100%;
  max-width: 100dvw;
  overflow-x: clip;

  & > .cmp-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}

.container_pre_content {
  min-height: 3.525rem;
}

.container {

  &.container--layout-50-50,
  &.container--layout-75-25,
  &.container--layout-25-25-25-25,
  &.container--layout-20-20-20-20-20 {
    > .cmp-container {
      display: flex;
      flex-direction: column;
      gap: $primitives-size-400;
    }
  }

  @media screen and (min-width: $breakpoint--desktop) {
    &.container--layout-flex-row {
      > .cmp-container {
        display: flex;
        flex-wrap: wrap;
        gap: $primitives-size-400;
      }
    }

    &.container--layout-100 {
      display: flex;
      flex-wrap: wrap;

      > .cmp-container {
        > div {
          width: calc(100% - $primitives-size-400);
        }
      }
    }

    &.container--layout-50-50 {
      > .cmp-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $primitives-size-400;

        > div {
          width: calc(50% - $primitives-size-200);
        }
      }
    }

    &.container--layout-25-75 {
      > .cmp-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $primitives-size-400;

        > div:nth-child(odd) {
          width: calc(25% - $primitives-size-200);
        }
        > div:nth-child(even) {
          width: calc(75% - $primitives-size-200);
        }
      }
    }

    &.container--layout-75-25 {
      > .cmp-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $primitives-size-400;

        > div:nth-child(odd) {
          width: calc(75% - $primitives-size-200);
        }
        > div:nth-child(even) {
          width: calc(25% - $primitives-size-200);
        }
      }
    }

    &.container--layout-33-33-33 {
      > .cmp-container {
        display: flex;
        flex-wrap: wrap;
        gap: $primitives-size-400;

        > div {
          width: calc(33% - 1.16rem);
        }
      }
    }

    &.container--layout-25-25-25-25 {
      > .cmp-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: $primitives-size-400;

        > div {
          width: calc(25% - $primitives-size-300);
        }
      }
    }

    &.container--layout-20-20-20-20-20 {
      > .cmp-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: $primitives-size-400;

        > div {
          width: calc(20% - $primitives-size-300);
        }
      }
    }

    &.container--layout-cinema {

      > .cmp-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: calc(100vh - 4rem);
        justify-content: center;
        
        .aem-AuthorLayer-Edit & {
          max-height: 1000px;
        }
      }

    }

  }

  &.container--center {
    > .cmp-container {
      justify-content: center;
    }
  }

  &.container--left {
    > .cmp-container {
      justify-content: flex-start;
    }
  }

  &.container--right {
    > .cmp-container {
      justify-content: flex-end;
    }
  }

  &.container--bg-black {
    background-color: $container-container-black;
  }

  &.container--bg-white {
    background-color: $container-container-white;
  }

  &.container--bg-green {
    background-color: $container-container-green;
  }

  &.container--bg-darkgreen {
    background-color: $container-container-darkgreen;
  }

  &.container--bg-grey {
    background-color: $container-container-grey;
  }

  &.container--bg-shadow {
    > .cmp-container {
      box-shadow: 0px 4px 4px 0px #00000040, inset 0 0 0 1000px rgba(0,0,0,.3);
    }
  }

  &.container--border-green {
    border: 2px solid $container-container-green;
    padding: 1rem;
  }

  &.container--space-cage {
    > .cmp-container {
      margin: 0 auto;
      max-width: $default-container-width;
      padding: 4rem $primitives-size-200;
    }
  }

  &.container--space-top-400 {
    > .cmp-container {
      margin-top: 0;
      padding-top: $primitives-size-400;
    }
  }

  &.container--space-bottom-400 {
    > .cmp-container {
      margin-bottom: 0;
      padding-bottom: $primitives-size-400;
    }
  }

  &.container--space-top-800 {
    > .cmp-container {
      margin-top: 0;
      padding-top: $primitives-size-800;
    }
  }

  &.container--space-bottom-800 {
    > .cmp-container {
      margin-bottom: 0;
      padding-bottom: $primitives-size-800;
    }
  }

  &.container--space-bottom-0 {
    > .cmp-container {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &.container--space-top-0 {
    > .cmp-container {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &.container--space-inner-400 {
    > .cmp-container {
      padding: $primitives-size-400 $primitives-size-400 $primitives-size-400 $primitives-size-400;
    }
  }

  &.container--layout-get-in-touch-left {
    @media screen and (max-width: $breakpoint--desktop) {
      .button--standalone {
        align-items: flex-start;
      }
      .image {
        flex-direction: row;
        margin-left: -1rem;
      }
    }
  }

  &.container--layout-get-in-touch-center {
    @media screen and (max-width: $breakpoint--desktop) {
      .cmp-title__text, .title, .text {
        text-align: center;
      }
    }
  }
}
