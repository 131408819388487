.cmp-text {
  color: $text-text-dark;
  display: flex;
  flex-direction: column;
  font-size: 1em;
  font-weight: normal;
  margin-bottom: $primitives-size-800;
  margin-top: $primitives-size-800;

  .container--bg-black &,
  .container--bg-grey & {
    color: $text-text-light;
  }

  hr {
    background: $borders-border-light;
    border: 0;
    height: 1px;
    margin-bottom: 1.5rem;
    width: 100%;
  }

  b {
    font-weight: bold;
  }

  p {
    max-width: $max-text-width;

    .container--layout-flex-row & {
      max-width: 100%;
    }
  }

  p a {
    border: 0.1rem solid transparent;
    border-radius: 0.25rem;
    color: $color-link;
    text-decoration: underline;
    text-decoration-color: $color-link-hover;
    text-decoration-style: solid;
    text-decoration-thickness: 0.1rem;

    &:hover {
      color: $text-text-dark;
      text-decoration: underline;

      .container--bg-black &,
      .container--bg-grey & {
        color: $text-text-light;
        text-decoration-color: $text-text-light;
      }
    }

    &:focus {
      margin: 0 8px;
      padding: 2px 5px;

      border-radius: 2px;
      box-shadow: 0px 0px 0px 2px #0065D1, 0px 0px 0px 4px #FFF, 0px 0px 0px 6px #0065D1;
    }

    .container--bg-black &,
    .container--bg-grey & {
      color: $color-link-dark;
      text-decoration-color: $color-link-hover-dark;
    }

    b {
      position: relative;

      /*&:after {
        background-color: $color-link;
        content: "";
        display: inline-block;
        height: 17px;
        right: -20px;
        mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNjY2OTkgMTEuMzMzM0wxMS4zMzM3IDQuNjY2NjIiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNC42NjY5OSA0LjY2NjYySDExLjMzMzdWMTEuMzMzMyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
        mask-size: cover;
        position: absolute;
        top: 3px;
        width: 17px;

        .container--bg-black &,
        .container--bg-grey & {
          background-color: $color-link-dark;
        }

        .container--bg-green & {
          background-color: $text-text-dark;
        }
      }*/
    }
  }

  blockquote {
    font-family: "akzidenz", "titleFont", "titleFont Fallback";
    font-size: 1.75em;
    font-style: italic;
    line-height: 2.375rem;
    margin: 0.75rem 0;
    margin-inline: 0;
  }

  ul,
  ol {
    font-size: $font-size-p-mobile;
    line-height: $line-height-p-mobile;
    margin: 0 0 1rem 0;
    max-width: $max-text-width;
    padding-left: 20px;

    ul {
      list-style-type: disc;
    }

    @media screen and (min-width: $breakpoint--desktop) {
      font-size: $font-size-p-desktop;
      line-height: $line-height-p-desktop;
    }
  
  }

  li {
    margin: 1rem 0;
  }

  .text--left & {
    align-items: flex-start;
    text-align: left;
  }

  .text--right & {
    align-items: flex-end;
    text-align: right;
  }

  .text--center & {
    align-items: center;
    text-align: center;
  }

  .text--color-dark & {
    color: $text-text-dark;
  }

  .text--color-light & {
    color: $text-text-light;
  }

  .text--color-green & {
    color: $text-text-green;
  }

  .text--color-darkgreen & {
    color: $text-text-darkgreen;
  }

  .text--color-grey & {
    color: $text-text-grey;
  }

  .text--space-bottom-800 & {
    margin-bottom: $primitives-size-800;
  }

  .text--space-bottom-400 & {
    margin-bottom: $primitives-size-400;
  }

  .text--space-bottom-0 & {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .text--space-top-800 & {
    margin-top: $primitives-size-800;
  }

  .text--space-top-400 & {
    margin-top: $primitives-size-400;
  }

  .text--space-top-0 & {
    margin-top: 0;
    padding-top: 0;
  }

  .text--pre-format-stepped & {
    p:first-child {

      @extend h1;
    }
    p:nth-child(2) {

      @extend h3;
    }
  }

  .text--tooltip & {
    position: relative;
    width: fit-content;

    p:first-child {
      cursor: pointer;
      font-size: 14px;
      position: relative;
      padding-left: 5px;

      &::before {
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBjbGFzcz0ibXItMiBoLTYgdy02IiBkYXRhLXRlc3RpZD0idXNlci1hY2Nlc3MtaW5mby1ib3gtaWNvbiI+PGcgaWQ9Ikljb24vSGVscCI+PHBhdGggaWQ9IlZlY3RvciIgZD0iTTEyIDIyQzE3LjUyMjggMjIgMjIgMTcuNTIyOCAyMiAxMkMyMiA2LjQ3NzE1IDE3LjUyMjggMiAxMiAyQzYuNDc3MTUgMiAyIDYuNDc3MTUgMiAxMkMyIDE3LjUyMjggNi40NzcxNSAyMiAxMiAyMloiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48L3BhdGg+PHBhdGggaWQ9IlZlY3Rvcl8yIiBkPSJNOS4wODk4NCA4Ljk5OTU5QzkuMzI0OTUgOC4zMzEyNiA5Ljc4OSA3Ljc2NzcgMTAuMzk5OCA3LjQwODczQzExLjAxMDYgNy4wNDk3NSAxMS43Mjg3IDYuOTE4NTMgMTIuNDI3IDcuMDM4M0MxMy4xMjUzIDcuMTU4MDggMTMuNzU4NyA3LjUyMTEyIDE0LjIxNDkgOC4wNjMxMkMxNC42NzEyIDguNjA1MTIgMTQuOTIwOSA5LjI5MTEyIDE0LjkxOTggOS45OTk1OUMxNC45MTk4IDExLjk5OTYgMTEuOTE5OCAxMi45OTk2IDExLjkxOTggMTIuOTk5NiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjwvcGF0aD48cGF0aCBpZD0iVmVjdG9yIDIiIGQ9Ik0xMiAxNlYxNi41IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PC9wYXRoPjwvZz48L3N2Zz4=");
        left: -10px;
        position: relative;
        top: 6px;
      }
    }

    p:nth-child(2) {
      background-color: $text-text-dark;
      border-radius: 3px;
      color: $text-text-light;
      font-size: 15px;
      display: none;
      line-height: 24px;
      padding: 1rem;
      position: relative;

      @media screen and (min-width: $breakpoint--desktop) {
        position: absolute;
        right: -270px;
        top: 6px;
        width: 240px;
      }
    }
  }

  &:hover {
    p:nth-child(2) {
      display: block;
    }
  }

  [data-type="Editable"] & {
    p:nth-child(2) {
      display: block;
    }
  }
}

.cmp-link__screen-reader-only {
  left: -100vw;
  position: absolute;
}
