//****************************************
// Delete after migration to new header
.main-header:not(:has(#header_new)) {
  background-color: transparent;
  left: 0;
  padding: 0;
  position: fixed;
  top: 0;
  transition: background-color 0.5s ease;
  width: 100%;
  z-index: 1000;

  // .aem-AuthorLayer-Edit & {
  //   top: 70px;
  // }

  ._js-main-nav--open &,
  &._js-inverted {
    background-color: $container-container-black;

    .button {
      .cmp-button {
        @extend %cta--primary;
      }
    }

  }

  .cmp-experiencefragment {
    color: $text-text-light;
    padding: 0.5rem 1rem;
    margin: 0 auto;
    max-width: $default-container-width;

    .cmp-image {
      margin: 0.3215rem 2.5rem 0 0;
      width: 10.8125rem;
      min-width: 10.8125rem;

      @media screen and (min-width: $breakpoint--desktop) {
        align-items: center;
        display: flex;
        margin-top: 0.625rem;
        width: 13.3125rem;
      }
    }


    .cmp-button {
      @extend %cta;
      @extend %cta--primary-inverted;
    }

    .cmp-container {
      display: flex;
      flex-direction: column;
      position: relative;

      @media screen and (min-width: $breakpoint--desktop) {
        justify-content: flex-start;
        flex-direction: row;
      }

      .image,
      .navigation,
      .button {
        display: flex;
        float: none;
      }

      .image {
        justify-content: flex-start;
      }
      .navigation {
        display: none;
        justify-content: flex-start;
        flex-grow: 1;

        @media screen and (min-width: $breakpoint--desktop) {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
      .button {
        display: none;
        justify-content: flex-end;

        @media screen and (min-width: $breakpoint--desktop) {
          display: block;
        }
      }

      &._js-open {
        height: calc(100dvh - 1rem);

        .navigation,
        .button {
          display: flex;
        }

        .navigation {
          order: 3;
        }

        .button {
          justify-content: flex-start;
          margin-top: 2rem;
          position: relative;

          .cmp-button {
            flex-grow: 1;
          }
        }

        #mobile-header-button {
          display: block;
        }
      }
    }
  }

  #mobile-header-button {
    background-color: transparent;
    border: none;
    display: block;
    padding-block: 0;
    padding-inline: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0.3215rem;

    &:before {
      background-color: transparent;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNiAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjAwMDM2NjIxMSAwSDI2LjAwMDRWNEgwLjAwMDM2NjIxMVYwWk0wLjAwMDM2NjIxMSAxMEgyNi4wMDA0VjE0SDAuMDAwMzY2MjExVjEwWk0yNi4wMDA0IDIwSDAuMDAwMzY2MjExVjI0SDI2LjAwMDRWMjBaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
      background-position: center;
      background-repeat: no-repeat;
      content: "";
      display: inline-block;
      height: 24px;
      vertical-align: middle;
      width: 26px;
    }

    &._js-openbtn:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjE3MTYzIDEwLjk5OTlMMC4zOTMzNzIgMTguNzc4MkwzLjIyMTggMjEuNjA2NkwxMS4wMDAxIDEzLjgyODRMMTguNzc4MyAyMS42MDY2TDIxLjYwNjggMTguNzc4MkwxMy44Mjg1IDEwLjk5OTlMMjEuNjA2NiAzLjIyMTg2TDE4Ljc3ODEgMC4zOTM0MzNMMTEuMDAwMSA4LjE3MTUyTDMuMjIxOTggMC4zOTM0NDNMMC4zOTM1NTcgMy4yMjE4N0w4LjE3MTYzIDEwLjk5OTlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
    }

    @media screen and (min-width: $breakpoint--desktop) {
      display: none;
    }
  }
}
//****************************************
