.cmp-content-component {}

// Theming when set on parent container
.container--bg-black .cmp-content-component {
  color: var(--cet-color-neutral-white);
}
// Theming within content component
// (1): Provide bg color as fallback when container was not set correctly ... Note: It will look ugly, because no spacings are set
.cmp-content-component--dark {
  color: var(--cet-color-neutral-white);
  background-color: var(--cet-color-neutral-black); // (1)
}

.cmp-content-component__title {}

.cmp-content-component__subtitle {}

// Increase specificity to trump spacing reset of ".cet-cmp-title"
.cmp-content-component__subtitle.cmp-content-component__subtitle  {
  margin-block-start: var(--cet-space-m);
}

// (1): Apply a max width for whole content to keep a readable line length.
// See "page layouts" at https://devstack.vwgroup.com/confluence/x/6odYJQ for further context
// Note: Following the current implementation in .cmp-text p {  max-width: $max-text-width;} to be consistent with the legacy styles
.cmp-content-component__content {
  margin-block-start: var(--cet-space-xs);
  max-width: $max-text-width; // (1)
}

.cmp-content-component__cta {
  margin-block-start:var(--cet-space-m);
}
