
.cmp-form-options {
  display: grid;
  border: none;
  padding: 0;
  position: relative;
}

.cmp-form-options--drop-down {
  &:after {
    content: "";
    position: absolute;
    right: 1rem;
    pointer-events: none;
    height:1.5rem;
    width: 1.5rem;
    background-repeat: none;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Imljb25zL2NoZXZyb25fZG93biI+CjxtYXNrIGlkPSJtYXNrMF8yODk4XzcyNTkiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+CjxyZWN0IGlkPSJCb3VuZGluZyBib3giIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMjg5OF83MjU5KSI+CjxwYXRoIGlkPSJpY29uIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTUuNjQ2NDUgOC42NDY0NUM1Ljg0MTcxIDguNDUxMTggNi4xNTgyOSA4LjQ1MTE4IDYuMzUzNTUgOC42NDY0NUwxMiAxNC4yOTI5TDE3LjY0NjQgOC42NDY0NUMxNy44NDE3IDguNDUxMTggMTguMTU4MyA4LjQ1MTE4IDE4LjM1MzYgOC42NDY0NUMxOC41NDg4IDguODQxNzEgMTguNTQ4OCA5LjE1ODI5IDE4LjM1MzYgOS4zNTM1NUwxMi4zNTM2IDE1LjM1MzZDMTIuMTU4MyAxNS41NDg4IDExLjg0MTcgMTUuNTQ4OCAxMS42NDY0IDE1LjM1MzZMNS42NDY0NSA5LjM1MzU1QzUuNDUxMTggOS4xNTgyOSA1LjQ1MTE4IDguODQxNzEgNS42NDY0NSA4LjY0NjQ1WiIgZmlsbD0iYmxhY2siLz4KPC9nPgo8L2c+Cjwvc3ZnPgo=");
    top: 2.2rem;
  }
}

.cmp-form-options__legend {
  display: none;
}
.cmp-form-options__help-message {
  color: $text-text-grey;
  font-size: 0.8rem;
  margin: 0 1.1rem;
}

.cmp-form-options__label {
  margin-bottom: 0.25rem;
}

.cmp-form-options__field--drop-down {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  border: 1px solid $borders-border-grey;
  border-radius: 0.125rem;
  padding: $primitives-size-150 $primitives-size-200;
  background-color: #fff;
  cursor: pointer;

  &:focus {
    border-color: $borders-border-darkgreen;
    outline: none;
  }
}

.cmp-form-options__field--multi-drop-down {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  border: 1px solid $borders-border-grey;
  border-radius: 0.125rem;
  padding: $primitives-size-200 $primitives-size-200;
  background-color: #fff;
  cursor: pointer;

  &:focus {
    border-color: $borders-border-darkgreen;
    outline: none;
  }

  option {
    padding: 0.5rem;
  }
}


// checkbox
.cmp-form-options--radio,
.cmp-form-options--checkbox {
  .cmp-form-options__field-label {
    padding: 0.5rem 0;
    display: flex;
  }

  .cmp-form-options__field-description {
    padding-left: 0.5rem;
  }
}