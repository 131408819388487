
// third-party License

.html {

    @media screen and (min-width: $breakpoint--desktop) {
        .lg\:w-2\/3 {
            width: 66.666667%;
        }
        .md\:text-textMdDesk {
            font-size: 1.5rem;
            line-height: 150%;
        }
    }


    .shadow-table {
        --tw-ring-shadow: 0 0 #0000;
        --tw-ring-offset-shadow: 0 0 #0000; 
        --tw-shadow: 0px 4px 8px -2px rgba(16,24,40,.1),0px 2px 4px -2px rgba(16,24,40,.06);
        --tw-shadow-colored: 0px 4px 8px -2px var(--tw-shadow-color),0px 2px 4px -2px var(--tw-shadow-color);
        box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow);
        box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    }

    .grid {
        display: grid;
    }
    .grid-cols-4 {
        grid-template-columns: repeat(4,minmax(0,1fr));
    }

    .col-span-3 {
        grid-column: span 3/span 3;
    }
    
    .bg-semantic-tableHeader {
        background-color: #f2f2f2;
    }

    .rounded-lg {
        border-radius: .5rem;
    }
    .border {
        border-width: 1px;
    }

    .border-b {
        border-bottom-width: 1px;
    }
    .border-grey-200 {
        border-color: #e4e7ec;
    }
    .border-dark {
        border: 1px solid #e4e7ec;
    }
    .w-full {
        width: 100%;
    }
    .p-4 {
        padding: 1rem;
    }
    .px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .pb-4 {
        padding-bottom: 1rem;
    }
    .pt-5 {
        padding-top: 1.25rem;
    }
    .font-bold {
        font-weight: 700;
    }
    .break-words {
        overflow-wrap: break-word;
    }
    .uppercase {
        text-transform: uppercase;
    }
    .font-akzidenz {
        font-family: akzidenz;
    }
    .text-left {
        text-align: left;
    }
    .my-12 {
        margin-bottom: 3rem;
        margin-top: 3rem;
    }

    .w-5 {
        width: 1.25rem;
    }
    .mr-7 {
        margin-right: 1.75rem;
    }

    .stroke-dark{ 

    }

    .flex {
        display: flex;
    }

    .hidden {
        display: none;
    }

    
    button, input, optgroup, select, textarea {
        color: inherit;
        font-family: inherit;
        font-size: 100%;
        font-weight: inherit;
        line-height: inherit;
        margin: 0;
        padding: 0;
    }

    button, select {
        text-transform: none;
        border: 0 solid #e5e7eb;
    }

    [type=button], [type=reset], [type=submit], button {
        -webkit-appearance: button;
        background-color: initial;
        background-image: none;
    }

    [role=button], button {
        cursor: pointer;
    }


}